import React from "react";
import Sidebar from "../../component/Slidebar.js";
import Laporan from "../../laporan/laporan.js";

const laporan = () => {
  return (
    <div>
       
      <Laporan />
    </div>
  );
};

export default laporan;
