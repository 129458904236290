import React from 'react'
import Sidebar from "../../component/Slidebar.js";
import Pengaturan from "../../pengaturan/pengaturan.js"

const pengaturan = () => {
  return (
    <div>
      <Sidebar/>
      <Pengaturan/>
    </div>
  )
}

export default pengaturan
