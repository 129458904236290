import React from 'react'
import Sidebar from "../../component/Slidebar.js";
import Pengguna from "../../Datapengguna/Datapengguna.js"

const datapengguna = () => {
  return (
    <div>
      <Sidebar/>
      <Pengguna/>
    </div>
  )
}

export default datapengguna
